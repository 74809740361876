<template>
  <div class="MultilineContent" v-html="formatted" />
</template>

<script>
/**
 * @module MultilineContent
 */
export default {
  name: 'MultilineContent',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    formatted () {
      return this.value.replace(/\n/g, '<br/>')
    }
  }
}
</script>
