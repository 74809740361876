export const MEDIA_BROAD_NARROW = () => ({
  broad_media: 'components.media_broad_narrow.broad_media',
  broad_specialist: 'components.media_broad_narrow.broad_specialist',
  narrow_specialist: 'components.media_broad_narrow.narrow_specialist',
  reviews: 'components.media_broad_narrow.reviews'
})

export const MEDIA_GEORGRAPHY = () => ({
  international: 'components.media_geography.international',
  national: 'components.media_geography.national',
  regional: 'components.media_geography.regional',
  local: 'components.media_geography.local'
})

export const MEDIA_PUBLICATION_INTERVAL = () => ({
  hourly: 'components.media_publication_interval.hourly',
  daily: 'components.media_publication_interval.daily',
  weekly: 'components.media_publication_interval.weekly',
  biweekly: 'components.media_publication_interval.biweekly',
  monthly: 'components.media_publication_interval.monthly',
  '6-monthly': 'components.media_publication_interval.6_monthly',
  yearly: 'components.media_publication_interval.yearly'
})

export const MEDIA_SOCIAL_TYPES = () => ({
  facebook: 'components.media_social_types.facebook',
  twitter: 'components.media_social_types.twitter',
  linkedin: 'components.media_social_types.linkedin',
  instagram: 'components.media_social_types.instagram',
  pinterest: 'components.media_social_types.pinterest',
  tiktok: 'components.media_social_types.tiktok',
  youtube: 'components.media_social_types.youtube',
  forum: 'components.media_types.forum',
  reddit: 'components.media_types.reddit',
  review: 'components.media_types.review'
})

export const MEDIA_CATEGORIES = {
  // next 3 are legacy and are kept here to be safe
  all: 'general.all',
  national_media: 'components.media_categories.national_media',
  regional_media: 'components.media_categories.regional_media',
  local_media: 'components.media_categories.local_media',
  ...MEDIA_BROAD_NARROW(),
  ...MEDIA_GEORGRAPHY(),
  ...MEDIA_PUBLICATION_INTERVAL(),
  ...MEDIA_SOCIAL_TYPES()
}

export const PRINT_CATEGORIES = () => (['national', 'regional', 'local', 'weekly', 'monthly'])

export const WEB_CATEGORIES = () => (['broad_media', 'broad_specialist', 'narrow_specialist', 'reviews'])

export const SOCIAL_CATEGORIES = () => (['facebook', 'twitter', 'linkedin', 'instagram', 'youtube'])

export const TV_CATEGORIES = () => (['national', 'regional', 'local', 'daily'])

export const RADIO_CATEGORIES = () => (['national', 'regional', 'local', 'daily'])
