<template>
  <editable-field
    ref="notes"
    v-loading="isSaving"
    :value="form.notes"
    :placeholder="$t('components.clipping_editable_note.placeholder', locale)"
    :empty-text="emptyText"
    :disabled="disabled"
    floating-label
    inline-placeholder
    control="EditableControlTextarea"
    class="m-t-s ClippingEditableNote"
    data-testid="ClippingNote"
    @input="saveNote"
    @cancel="form.notes = $event"
  >
    <multiline-content :value="form.notes" />
  </editable-field>
</template>

<script>
import EditableField from '../forms/controls/EditableField'
import MultilineContent from '../core/MultilineContent'

/**
 * @module ClippingEditableNote
 */
export default {
  name: 'ClippingEditableNote',
  components: { MultilineContent, EditableField },
  props: {
    notes: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      isSaving: false,
      form: {
        notes: ''
      }
    }
  },
  computed: {
    emptyText () {
      const text = !this.disabled ? 'components.clipping_editable_note.empty_text' : 'components.clipping_editable_note.empty_text_disabled'
      return this.$t(text, this.locale)
    }
  },
  watch: {
    notes: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal === this.form.notes) return
        this.form.notes = newVal
      }
    }
  },
  methods: {
    async saveNote (value) {
      this.form.notes = value
      try {
        this.isSaving = true
        await this.$api.patchData(`clippings/${this.id}`, { notes: this.form.notes })
        this.$refs.notes && this.$refs.notes.stopEditing()
        this.$emit('update:notes', this.form.notes)
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>
