<template>
  <div
    v-if="!$isEmpty(clipping)"
    class="ClippingPreview has-text-black"
  >
    <div
      :class="{ 'is-aligned-middle': $isReport }"
      class="columns ClippingPreview__Inner"
    >
      <div class="column is-8 ClippingPreview__Image">
        <div class="ClippingPreview__Image-Inner">
          <div class="preview">
            <ClippingPreviewFiles
              :clipping="clipping"
            />
          </div>
        </div>
      </div>
      <div class="column is-4 ClippingPreview__Body">
        <div class="ClippingPreview__Body-Wrap">
          <div class="has-text-centered has-text-weight-bold m-b-l">
            <div
              v-if="clipping.media_outlet.data.name"
              :title="$t('components.clipping_preview.media_title', locale)"
              class="is-size-2 m-b-m leading-tight"
              data-testid="clipping-mediaTitle"
            >
              {{ truncate(clipping.media_outlet.data.name, 60) }}
            </div>
            <div
              v-if="clipping.data.headline"
              :title="$t('general.headline', locale)"
              class="m-b-m is-size-5 leading-tight"
              data-testid="clipping-headline"
            >
              <ClippingEditableHeadline
                :id="clipping.id"
                :headline="clipping.data.headline"
                :disabled="!editable"
              />
            </div>
            <div
              v-if="clipping.data.translated_headline || clipping.is_translatable"
              :title="$t('general.translated_headline', locale)"
              class="m-b-m is-size-5 has-text-weight-normal has-text-grey is-italic"
              data-testid="clipping-headline"
            >
              <ClippingEditableTranslatedHeadline
                :id="clipping.id"
                :headline="clipping.data.translated_headline"
                :disabled="!editable"
              />
            </div>
            <div
              :title="$t('general.publication_date', locale)"
              data-testid="clipping-publishedAt"
            >
              {{ datetime(clipping.published_at, 'DD.MM.YYYY') }}
            </div>
          </div>
          <table class="ClippingPreview__largeMetaTable m-b-xl">
            <tr data-testid="clipping-hfValue">
              <td>{{ $t('general.hypefactors_value', locale) }}</td>
              <td>
                <template v-if="hfValue">
                  <div v-html="$moneyAbbrFormatHtml(hfValue)" />
                </template>
                <template v-else>
                  {{ $t('general.not_available', locale) }}
                </template>
              </td>
            </tr>
            <tr>
              <td> {{ $t('general.impressions', locale) }}</td>
              <td data-testid="clipping-impressions">
                <template v-if="clipping.impressions">
                  {{ formatImpressionsValue(clipping.impressions) }}
                </template>
                <template v-else>
                  {{ $t('general.not_available', locale) }}
                </template>
              </td>
            </tr>
            <tr>
              <td>{{ $t('components.clipping_preview.clipping_type_and_category', locale) }}</td>
              <td>
                <div data-testid="clipping-mediaType" :title="$t('general.media_type', locale)">
                  {{ mediaType }}
                </div>
                <div
                  :title="$t('components.clipping_preview.media_category', locale)"
                  data-testid="clipping-mediaCategory"
                  class="is-size-4 has-text-weight-normal has-nowrap"
                >
                  {{ $t(mediaCategory, locale) }}
                </div>
              </td>
            </tr>
          </table>

          <div class="columns m-b-none">
            <div class="column">
              <ClippingPreviewMeta :title="$t('general.sentiment', locale)" data-testid="clipping-sentiment">
                <div class="icon align-middle">
                  <VIcon :type="sentiment.icon" fallback />
                </div>
                {{ $t(sentiment.label, locale) }}
              </ClippingPreviewMeta>
            </div>
            <div class="column">
              <ClippingPreviewMeta
                :title="$t('components.clipping_preview.topic', locale)"
                data-testid="clipping-topic"
              >
                <template v-if="clipping.topic">
                  {{ $t('components.clipping_topics.' + clipping.topic, locale) }}
                </template>
                <template v-else>
                  {{ $t('general.not_available', locale) }}
                </template>
              </ClippingPreviewMeta>
            </div>
          </div>
          <div class="columns m-b-none">
            <div class="column">
              <ClippingPreviewMeta :title="$t('components.clipping_preview.country', locale)" data-testid="clipping-country">
                <div v-if="!$isEmpty(country)" class="is-flex is-aligned-middle">
                  <CountryFlag :country="country.iso_alpha2" />
                  <span>{{ country.name }}</span>
                </div>
                <template v-else>
                  {{ $t('general.not_available', locale) }}
                </template>
              </ClippingPreviewMeta>
            </div>
            <div class="column">
              <ClippingPreviewMeta
                :title="$t('general.tags', locale)"
                data-testid="clipping-tags"
              >
                <DelimitedList v-if="clippingTags.length" #default="{ val: tag }" :value="clippingTags">
                  <span data-testid="ClippingTag">{{ tag.title }}</span>
                </DelimitedList>
                <div v-else>
                  {{ $t('components.clippings.clipping_preview.no_tags', locale) }}
                </div>
              </ClippingPreviewMeta>
            </div>
          </div>
          <ClippingPreviewMeta
            :title="$t('general.notes', locale)"
            class="ClippingPreview__notes"
            data-testid="clipping-notes"
          >
            <ClippingEditableNote
              :id="clipping.id"
              :locale="locale"
              :notes="clipping.notes"
              :disabled="!editable"
            />
          </ClippingPreviewMeta>
          <ClippingPreviewMeta
            v-if="clipping.source_link"
            :title="$t('general.view_mention')"
            data-testid="clipping-url"
          >
            <a
              :href="clipping.source_link"
              target="_blank"
              class="has-ellipsis is-block"
            >
              <template v-if="clipping.type === 'tv'">{{ $t('general.view_on') }} TVEyes.com</template>
              <template v-else>{{ $t('general.view_on') }} {{ clipping.url_domain }}</template>
            </a>
          </ClippingPreviewMeta>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'
import FormatImpressionsValueMixin from '@hypefactors/shared/js/mixins/FormatImpressionsValueMixin'

import { MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'
import { MEDIA_CATEGORIES } from '@hypefactors/shared/js/constants/mediaOutletCategories'
import { SENTIMENT_NUMBER_TO_SLUG, SENTIMENT_TYPES } from '@hypefactors/shared/js/constants/sentimentTypes'
import CountryFlag from '@hypefactors/shared/js/components/core/CountryFlag'
import DelimitedList from '@hypefactors/shared/js/components/core/DelimitedList'
import VIcon from '@hypefactors/shared/js/components/core/VIcon'

import ClippingPreviewMeta from './ClippingPreviewMeta'
import ClippingPreviewFiles from './ClippingPreviewFiles'
import ClippingEditableNote from './ClippingEditableNote'
import ClippingEditableHeadline from './ClippingEditableHeadline'
import ClippingEditableTranslatedHeadline from './ClippingEditableTranslatedHeadline'

/**
 * Renders a clipping preview. Used on the {@see module:ClippingPreviewModal} and {@see module:report/Measure}
 * @module ClippingPreview
 */
export default {
  name: 'ClippingPreview',

  components: {
    CountryFlag,
    DelimitedList,
    VIcon,
    ClippingPreviewMeta,
    ClippingEditableNote,
    ClippingEditableHeadline,
    ClippingEditableTranslatedHeadline,
    ClippingPreviewFiles
  },

  mixins: [TruncateMixin, DatetimeMixin, FormatImpressionsValueMixin],

  props: {
    clipping: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      required: false,
      default: null
    }
  },

  data: () => ({
    mediaCategories: MEDIA_CATEGORIES,
    mediaTypes: MEDIA_TYPES
  }),

  computed: {
    mediaCategory () {
      return this.$safeGet(this.mediaCategories, this.clipping.media_outlet.data.media_category) || 'general.unspecified'
    },

    mediaType () {
      if (!this.clipping.type) return null
      return this.mediaTypes.hasOwnProperty(this.clipping.type) ? this.$t(this.mediaTypes[this.clipping.type].label, this.locale) : this.clipping.type
    },

    hasTier () {
      return this.tier !== 'no-tier'
    },

    tier () {
      return this.clipping.tier || 'no-tier'
    },

    country () {
      return this.$safeGet(this.clipping, 'country.data')
    },

    clippingBrandId () {
      return this.$safeGet(this.clipping, 'brand.data.id', this.activeBrandId)
    },

    hfValue () {
      return this.clipping.hf_value
    },

    clippingTags () {
      return this.$safeGet(this.clipping, 'tags.data', [])
    },

    sentiment () {
      const slug = SENTIMENT_NUMBER_TO_SLUG[this.clipping.text_rating]
      return SENTIMENT_TYPES[slug]
    }
  }
}
</script>

<style
  rel='stylesheet/scss'
  lang='scss'
>
@import '~utils';

.ClippingPreview {
  width: 100%;
  flex: 1 1 auto;
  height: 100%;
  font-size: $body-size;

  &__Inner {
    height: 100%;
    align-items: center;
  }

  &__Body {
    display: flex;
    padding-left: 0;

    &-Wrap {
      width: 100%;
      padding-left: 10px;
    }
  }

  &__Row {
    margin-bottom: $margin;
  }

  &__Image-Inner {
    @include desktop {
      padding-right: 4rem;
    }
  }

  &__largeMetaTable {
    width: 100%;

    tr {
      border-bottom: 1px solid $grey-light
    }

    td {
      padding: $padding-large $padding;
      vertical-align: middle;

      &:first-of-type {
        font-size: 1.1em;
        text-transform: uppercase;
        letter-spacing: .07em;
        text-align: left;
        @include rtl {
          text-align: right;
        }
      }

      &:last-of-type {
        text-align: right;
        font-weight: bold;
        font-size: $size-2;
        @include rtl {
          text-align: left;
        }

        .currency-symbol {
          font-size: 0.8em;
        }
      }
    }
  }

  &__notes .textarea {
    font-weight: $hf__font-weight-bolder;
  }
}
</style>
