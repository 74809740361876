<template>
  <el-dialog
    :title="$t('components.clipping_preview_modal.title')"
    :visible.sync="visible"
    :before-close="beforeClose"
    width="100%"
    class="ClippingPreviewModal is-fullscreen"
  >
    <ClippingPreview
      v-if="visible"
      :clipping="clipping"
      editable
      class="p-h-m"
    />
  </el-dialog>
</template>

<script>
import ClippingPreview from '@hypefactors/shared/js/components/clippings/ClippingPreview'
import _clone from 'lodash/cloneDeep'

/**
 * Generates a modal with the provided clipping' preview
 * @see module:ClippingPreview
 * @module ClippingPreviewModal
 */
export default {
  components: {
    ClippingPreview
  },
  data () {
    return {
      clipping: {},
      visible: false
    }
  },
  mounted () {
    this.$bus.$on('ClippingPreviewModal:open', this.handleOpenEvent)
  },
  beforeDestroy () {
    this.$bus.$off('ClippingPreviewModal:open')
  },
  methods: {
    handleOpenEvent (clipping) {
      this.fetchClipping(clipping.id)
    },
    fetchClipping (id) {
      this.clipping = {}
      this.$api.getData(`clippings/${id}?include=country,tags`)
        .then(clipping => {
          this.clipping = _clone(clipping)
          this.visible = true
        })
        .catch(error => {
          this.$handleError(error)
        })
    },
    beforeClose (close) {
      // TODO: Dobri - Stop modal from closing on "escape" if lightGallery is opened. As dirty as it gets...
      if (document.body.classList.contains('lg-on')) return
      /**
       * Stop modal from closing when {@see ClippingEditableNote} is being edited.
       */
      const note = this.$el.querySelector('.ClippingEditableNote')
      if (note && note.classList.contains('EditableField--editing')) return
      close()
    }
  }
}
</script>
