<template>
  <div class="ClippingPreviewFiles">
    <div v-if="thumbnail && clipping.type !== 'print'" class="has-text-centered">
      <img
        :src="thumbnail"
        :alt="altText"
        class="thumbnail-preview"
      >
    </div>
    <template v-else-if="hasFiles">
      <template v-if="$isReport">
        <div class="ClippingPreviewFiles-grid">
          <clipping-preview-files-file
            v-for="(file, index) in clipping.files"
            :key="index"
            :file="file"
            :is-print="clipping.type === 'print'"
          />
        </div>
      </template>
      <el-carousel v-else :autoplay="false" height="500px">
        <el-carousel-item
          v-for="(file, index) in clipping.files"
          :key="index"
        >
          <clipping-preview-files-file
            :file="file"
            :is-print="clipping.type === 'print'"
          />
        </el-carousel-item>
      </el-carousel>
    </template>
    <div v-else class="has-text-centered">
      <img
        :src="$asset('/images/sections/performance/fallback_clipping_thumbnail.png')"
        :alt="altText"
        class="thumbnail-preview"
      >
    </div>
  </div>
</template>

<script>
import ClippingPreviewFilesFile from './ClippingPreviewFilesFile'
import { ImageResizerService } from '../../services/ImageResizerService'

/**
 * Renders the clipping's files differently depending on type and if on report or not.
 * Reports get a table where as the app receives a carousel
 * @module ClippingPreviewFiles
 */
export default {
  components: { ClippingPreviewFilesFile },
  props: {
    clipping: {
      type: Object,
      default: () => ({
        headline: '',
        thumbnail: [],
        files: []
      })
    }
  },
  computed: {
    /**
     * Returns the first thumbnail if possible, else returns the first file or null
     * @return {String}
     */
    thumbnail () {
      const thumbnail = this.$safeGet(this.clipping, 'thumbnail.0', null)
      return !thumbnail ? null : ImageResizerService.resize(thumbnail, 1024, 960, { quality: 80 })
    },
    hasFiles () {
      return Array.isArray(this.clipping.files) && this.clipping.files.length > 0
    },
    altText () {
      return this.clipping.headline || this.$t('general.clipping')
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.ClippingPreviewFiles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: minmax(50px, 1fr);
  grid-gap: 1rem;
  justify-items: center;
  max-height: 960px;

  .ClippingPreviewFilesFile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .ClippingPreviewImage__file {
    max-height: 100%;
    max-width: 100%;
    flex: 1 1 auto;
  }
}
</style>
