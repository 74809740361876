<template>
  <editable-field
    ref="headline"
    v-loading="isSaving"
    :value="form.headline"
    :placeholder="$t('components.clipping_editable_translated_headline.placeholder')"
    :empty-text="emptyText"
    :disabled="disabled"
    floating-label
    inline-placeholder
    control="EditableControlTextarea"
    class="m-t-s ClippingEditableTranslatedHeadline"
    data-testid="ClippingTranslatedHeadline"
    @input="save"
    @cancel="form.headline = $event"
  />
</template>

<script>
import EditableField from '../forms/controls/EditableField'

/**
 * @module ClippingEditableTranslatedHeadline
 */
export default {
  name: 'ClippingEditableTranslatedHeadline',
  components: { EditableField },
  props: {
    id: {
      type: String,
      required: true
    },
    headline: {
      type: String,
      required: false,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isSaving: false,
      form: {
        headline: ''
      }
    }
  },
  computed: {
    emptyText () {
      const text = !this.disabled ? 'components.clipping_editable_translated_headline.empty_text' : 'components.clipping_editable_translated_headline.empty_text_disabled'
      return this.$t(text)
    }
  },
  watch: {
    headline: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal === this.form.headline) return
        this.form.headline = newVal
      }
    }
  },
  methods: {
    async save (value) {
      this.form.headline = value
      try {
        this.isSaving = true
        await this.$api.patchData(`clippings/${this.id}`, {
          data: {
            translated_headline: this.form.headline
          }
        })
        this.$refs.headline.stopEditing()
        this.$emit('update:translated-headline', this.form.headline)
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>
