<template>
  <div class="ClippingPreviewMeta p-a-m border-b-grey-light">
    <div class="is-uppercase tracking-wider">
      {{ title }}:
    </div>
    <div class="has-text-weight-bold">
      <slot />
    </div>
  </div>
</template>

<script>
/**
 * @module ClippingPreviewMeta
 */
export default {
  name: 'ClippingPreviewMeta',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

</style>
